import React from 'react';
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Header.css'; // Importe seu arquivo CSS personalizado aqui

import imageleft from '../images/contato1.png';
import portao from '../images/portao1.jpg';
import janela from '../images/janela-ok.jpeg';
import balcao from '../images/fechamentohome.jpg';
import imagemVidros from '../images/imagem-vidros.jpeg';

import acm from '../images/ACM.jpg';
import acm2 from '../images/ACM2.jpg';
import acm3 from '../images/ACM3.jpg';

const HomePage = () => {

    return (
        <div>
            <section id="about-us" className="bg-dark py-5">
                <Container>
                    <Row>
                        <Col md={6} className='mb-2'>
                            <Carousel>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={acm}
                                        alt="Projeto 1"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={acm2}
                                        alt="Projeto 2"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={acm3}
                                        alt="Projeto 3"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                        <Col md={6} className="d-flex flex-column justify-content-center align-items-center">
                            <div className="text-center">
                                <h3 style={{ color: 'white' }}>Portas ACM</h3>
                                <h1>Portas que Transformam Entradas em Elegância Pura</h1>
                                <p style={{ color: 'white' }}>
                                Descubra a revolução em design e funcionalidade com nossas Portas de ACM. Confeccionadas com precisão e inovação, nossas portas combinam estética moderna e durabilidade excepcional. Redefina o conceito de entrada, escolhendo o melhor em qualidade, estilo e resistência. Seja bem-vindo à era da sofisticação, onde cada porta não é apenas uma entrada, mas uma afirmação de elegância incomparável.
                                </p>
                                <div>
                                    <Link to="/contact">
                                        <Button variant="outline-warning">Contato</Button>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section id="portfolio" className="bg-light py-5">
                <Container>
                    <Row>
                        <Col md={6}>
                            <img
                                src={imagemVidros}
                                alt="Imagem Lado Esquerdo"
                                className="img-fluid mb-4"
                            />
                        </Col>
                        <Col md={6} className="d-flex flex-column justify-content-center align-items-center">
                            <div className="text-center">
                                <h3>Sobre nós</h3>
                                <h1>SOFISTICAÇÃO E MODERNIDADE</h1>
                                <p>
                                    Yellow Frames é uma empresa especializada em criar e fabricar Esquadrias de Alumínio de alta qualidade, oferecendo também a instalação de projetos modernos e sofisticados. Nosso compromisso é a excelência, desde a concepção até a execução, para proporcionar a você soluções elegantes que transformam seus espaços com estilo e durabilidade incomparáveis.
                                </p>
                                <div>
                                    <Link to="/aboutus">
                                        <Button variant="outline-warning">Sobre nós</Button>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section id="portfolio" className="py-5">
                <Container>
                    <Row>
                        <Col md={6} className="d-flex flex-column justify-content-center align-items-center">
                            <div className="text-center">
                                <h3>Portfólio</h3>
                                <h1>CONHEÇA NOSSOS PROJETOS</h1>
                                <p>
                                    Com um histórico sólido e abrangente no mercado, orgulhamo-nos de apresentar uma extensa gama de linhas exclusivas de Esquadrias de Alumínio. Nossa missão é elevar a beleza e modernidade dos ambientes, sempre buscando a excelência em cada projeto que realizamos. Explore alguns dos nossos projetos e testemunhe como transformamos espaços com nossa expertise e paixão pelo design.
                                </p>
                                <div className='mb-4'>
                                    <Link to="/portfolio">
                                        <Button variant="outline-warning">Portfólio Completo</Button>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <Carousel>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={portao}
                                        alt="Projeto 1"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={janela}
                                        alt="Projeto 2"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={balcao}
                                        alt="Projeto 3"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section id="contact" className="bg-light py-5">
                <Container>
                    <Row>
                        <Col md={6}>
                            <img
                                src={imageleft}
                                alt="Imagem Lado Esquerdo"
                                className="img-fluid"
                            />
                        </Col>
                        <Col md={6} className="d-flex flex-column justify-content-center align-items-center">
                            <div className="text-center">
                                <h3>Contato</h3>
                                <h1>SOLUÇÕES FEITAS PARA VOCÊ</h1>
                                <p>
                                    Com nossa vasta experiência no mercado, estamos prontos para fornecer soluções de esquadrias de alumínio exclusivas, projetadas para aprimorar a estética e a modernidade dos seus espaços. Explore alguns de nossos projetos inspiradores e, caso deseje discutir suas necessidades ou iniciar um projeto, não hesite em entrar em contato conosco. Estamos aqui para atendê-lo e transformar suas ideias em realidade.
                                </p>
                                <div className='mb-4'>
                                    <Link to="/contact">
                                        <Button variant="outline-warning">Contato</Button>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default HomePage;

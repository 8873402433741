import React from 'react';
import { Container } from 'react-bootstrap';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="bg-dark text-white text-center py-3">
            <Container>
                <p>
                    Rua Flamboyant, 100. Bairro Pinhal - Boituva-SP. CEP: 18550-000
                </p>
                <p>YellowFrames@Todos os direitos reservados - Desenvolvido por Alisson Arruda (contatoalisson.arruda@outlook.com)</p>
                <div>
                    <a
                        href="https://wa.me/5515981684550?text=Ol%C3%A1%2C+vim+atrav%C3%A9s+do+site+e+gostaria+de+realizar+um+or%C3%A7amento+com+voc%C3%AAs%21"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FaWhatsapp className="mx-2" />
                    </a>
                    <a
                        href="https://www.instagram.com/yellowframes_/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FaInstagram className="mx-2" />
                    </a>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
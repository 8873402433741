import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const ContactPage = () => {
    return (
        <div>
            <Container className="py-5">
                <Row>
                    <Col md={6} className="d-flex flex-column justify-content-center">
                        <div className="text-left">
                            <h1 style={{ color: '#f8d85a', fontSize: '5rem' }}>Contato</h1>
                            <p style={{ fontSize: '1rem' }}>Traga o seu projeto para a Yellow Frames e garanta o melhor para sua obra com soluções únicas e personalizadas.</p>
                            <h2 style={{ color: '#f8d85a', fontSize: '2.5rem' }}>E-mail</h2>
                            <p style={{ fontSize: '1rem' }}>yellowframes.esquadrias@gmail.com</p>
                            <h2 style={{ color: '#f8d85a', fontSize: '2.5rem' }}>Telefone</h2>
                            <p style={{ fontSize: '1rem' }}>(15) 98168-4550</p>
                            <h2 style={{ color: '#f8d85a', fontSize: '2.5rem' }}>Endereço</h2>
                            <p style={{ fontSize: '1rem' }}>Rua Flamboyant, 100 - Bairro Pinhal</p>
                            <p style={{ fontSize: '1rem' }}>Boituva - São Paulo</p>
                            <p style={{ fontSize: '1rem' }}>CEP: 18550-000</p>
                        </div>
                    </Col>
                    <Col md={6} className="d-flex align-items-center">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3663.6212159272586!2d-47.63048902375942!3d-23.329498153116635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5e494d2b7f71b%3A0xeb0efe19e6bb9132!2sR.%20Flamboyant%2C%20Boituva%20-%20SP%2C%2018550-000!5e0!3m2!1spt-BR!2sbr!4v1697050558840!5m2!1spt-BR!2sbr"
                            width="100%"
                            height="700px"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            title="Google Map"
                        ></iframe>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ContactPage;

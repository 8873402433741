import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

const images = {
  Blindex: [
    require('../images/esquadrias.jpeg'),
    require('../images/esquadrias1.jpeg'),
    require('../images/esquadrias2.jpg'),
    require('../images/esquadrias3.jpeg'),
    require('../images/esquadrias4.jpg'),
    require('../images/esquadrias5.jpg'),
    require('../images/esquadrias6.jpeg'),
    require('../images/boxlinhaspreta.jpeg'),
    require('../images/boxlinhasbranca.jpeg'),
  ],
  Esquadrias: [
    require('../images/blindex1.jpg'),
    require('../images/blindex2.jpg'),
    require('../images/blindex3.jpeg'),
    require('../images/blindex5.jpg'),
    require('../images/blindex6.jpg'),
    require('../images/blindex7.jpeg'),
    require('../images/blindex8.jpeg'),
  ],
  Portões: [
    require('../images/portao10.jpg'),
    require('../images/portao11.jpg'),
    require('../images/portao12.jpg'),
    require('../images/portaobrancogrande.jpeg')
  ],
  ACM: [
    require('../images/ACM11.jpeg'),
    require('../images/ACM12.jpeg'),
    require('../images/ACM13.jpeg')
  ]
};

const Portfolio = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const filteredImages = selectedCategory
    ? images[selectedCategory]
    : Object.values(images).flat();

  return (
    <Container className='py-3'>
      <div>
        <h2 className="text-center">Filtro por categoria:</h2>
        <Row className="justify-content-center">
          <Col xs="auto" className="mb-2">
            <Button
              variant="outline-warning"
              className="text-dark"
              onClick={() => handleCategoryChange(null)}
            >
              Todas
            </Button>
          </Col>
          {Object.keys(images).map((category, index) => (
            <Col xs="auto" className="mb-2" key={index}>
              <Button
                variant="outline-warning"
                className="text-dark"
                onClick={() => handleCategoryChange(category)}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </Button>
            </Col>
          ))}
        </Row>
      </div>

      <div className="mt-3">
        <h2 className="text-center">Selecione a categoria: {selectedCategory || 'Todas'}</h2>
        <Row>
          {filteredImages.map((image, imageIndex) => (
            <Col key={imageIndex} sm={6} md={4} lg={3} className="mb-4">
              <img src={image} alt="" className="img-fluid" />
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
};

export default Portfolio;

import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import yellowFrames from '../images/Yellow.mp4';
import imageleft from '../images/contato1.png';

const About = () => {
    return (
        <div>
            <section className="bg-light py-3">
                <Container>
                    <Row className='mb-2'>
                        <Col md={8}>
                            <div className="d-block">
                                <h1>SOBRE A EMPRESA</h1>
                                <p>
                                    A Yellow Frames é uma empresa especializada em esquadrias de alumínio, atendendo projetos de pequeno, médio e grande porte. Criação de projetos, fabricação, montagem e instalação. Fornecemos produtos e serviços de alto padrão que enobrecem projetos de residências em todo território nacional.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="bg-light mx-auto">
                <Container>
                    <Row>
                        <video
                            width="100%"
                            height="400"
                            autoPlay
                            loop
                            muted
                        >
                            <source src={yellowFrames} type="video/mp4" />
                            Seu navegador não suporta a reprodução de vídeo.
                        </video>
                    </Row>
                </Container>
            </section>

            <section className="bg-light py-3">
                <Container>
                    <Row>
                        <Col md={6} className="d-flex flex-column justify-content-center align-items-center">
                            <div className="text-left">
                                <h1>NOSSA MISSÃO</h1>
                                <p>
                                    É atendermos Casa, Condomínios, Edifícios e Construtoras, oferecendo uma grande variedade e inovação para execução do seu projeto em vidros, esquadrias de pequeno, médio e grande escala, aliados à excelente técnicas de montagem que proporcionam um resultado final esteticamente belo, e ao mesmo tempo seguro.
                                </p>
                            </div>
                        </Col>
                        <Col md={6} className="d-flex flex-column justify-content-center align-items-center">
                            <div className="text-left">
                                <h1>NOSSA VISÃO</h1>
                                <p>
                                    Nossa visão é criar esquadrias de alumínio e vidro que unam beleza e funcionalidade. Priorizamos a harmonia entre espaços internos e externos, enquanto promovemos práticas sustentáveis. Com foco na qualidade, criatividade e satisfação do cliente, moldamos o futuro da arquitetura, uma esquadria por vez, inspirando ambientes excepcionais e eco-friendly.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section id="contact" className="bg-light py-3">
                <Container>
                    <Row>
                        <Col md={6}>
                            <img
                                src={imageleft}
                                alt="Imagem Lado Esquerdo"
                                className="img-fluid"
                            />
                        </Col>
                        <Col md={6} className="d-flex flex-column justify-content-center align-items-center">
                            <div className="text-center">
                                <h3>Contato</h3>
                                <h1>SOLUÇÕES FEITAS PARA VOCÊ</h1>
                                <p>
                                    Com nossa vasta experiência no mercado, estamos prontos para fornecer soluções de esquadrias de alumínio exclusivas, projetadas para aprimorar a estética e a modernidade dos seus espaços. Explore alguns de nossos projetos inspiradores e, caso deseje discutir suas necessidades ou iniciar um projeto, não hesite em entrar em contato conosco. Estamos aqui para atendê-lo e transformar suas ideias em realidade.
                                </p>
                                <div>
                                    <Link to="/contact">
                                        <Button variant="outline-warning">Contato</Button>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default About;

import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton = () => {
    return (
        <div
            style={{
                position: 'fixed',
                bottom: '35px',
                right: '25px',
                backgroundColor: '#25d366',
                padding: '13px',
                borderRadius: '50%',
                zIndex: '1000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
            }}
        >
            <a
                href="https://wa.me/5515992412649?text=Ol%C3%A1%2C+vim+atrav%C3%A9s+do+site+e+gostaria+de+realizar+um+or%C3%A7amento%21"
                target="_blank"
                rel="noreferrer"
            >
                <FaWhatsapp style={{ color: '#fff', fontSize: '24px' }} />{/* Ajuste o tamanho do ícone */}
            </a>
        </div>
    );
};

export default WhatsAppButton;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { BsInstagram, BsWhatsapp } from 'react-icons/bs';
import logo from '../images/Logo Vertical Yellow.png';

const Header = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false);

    const toggleNav = () => {
        setIsNavExpanded(!isNavExpanded);
    };

    return (
        <Navbar expand="lg" bg="dark" variant="dark" expanded={isNavExpanded}>
            <Container>
                <Navbar.Brand className="align-items-center">
                    <img
                        src={logo}
                        alt="YellowFrames Logo"
                        width="50"
                        height="50"
                        className="d-inline-block align-items-center"
                        style={{ marginRight: '10px' }}
                    />
                    <span>YellowFrames</span>
                </Navbar.Brand>
                <Navbar.Toggle onClick={toggleNav} aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav">
                    <Nav className="mx-auto">
                        <Nav.Item>
                            <Link to="/" className="nav-link" onClick={toggleNav} style={{ fontSize: '20px' }}>
                                Home
                            </Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/aboutus" className="nav-link" onClick={toggleNav} style={{ fontSize: '20px' }}>
                                Sobre
                            </Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/portfolio" className="nav-link" onClick={toggleNav} style={{ fontSize: '20px' }}>
                                Portfólio
                            </Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/contact" className="nav-link" onClick={toggleNav} style={{ fontSize: '20px' }}>
                                Contato
                            </Link>
                        </Nav.Item>
                    </Nav>
                    <Nav>
                        <Nav.Item>
                            <a
                                href="https://www.instagram.com/yellowframes_/"
                                className="nav-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ fontSize: '20px' }}
                            >
                                <BsInstagram />
                            </a>
                        </Nav.Item>
                        <Nav.Item>
                            <a
                                href="https://wa.me/5515992412649?text=Ol%C3%A1%2C+vim+atrav%C3%A9s+do+site+e+gostaria+de+realizar+um+or%C3%A7amento%21"
                                className="nav-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ fontSize: '20px' }}
                            >
                                <BsWhatsapp />
                            </a>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
